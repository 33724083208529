/** @format */

import ChevronDown from '@/assets/icons/landing/chevron-down.svg';
import Search from '@/assets/icons/landing/search.svg';
import { i18nContext } from '@/context/i18nContext';
import { useDelayedUnmount } from '@/hooks/useDelayedUnmount';
import { useLockScroll } from '@/hooks/useLockScroll';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Content } from './Content';
import {
  Dropdown,
  DropdownContent,
  DropdownContentMobile,
  DropdownTrigger,
  Overlay,
  SearchButton,
  SelectButton,
} from './index.style';

export const CitySelectionDropdown: FC<Readonly<CitySelectionDropdownProps>> = ({ type }) => {
  const { landing: t } = useContext(i18nContext);
  const { lockScroll, unlockScroll } = useLockScroll();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRefDesktop = useRef<HTMLDivElement>(null);
  const dropdownRefMobile = useRef<HTMLDivElement>(null);

  const shouldRenderDesktop = useDelayedUnmount(isDropdownVisible, 100);
  const shouldRenderMobile = useDelayedUnmount(isDropdownVisible, 400);

  const scrollToDropdown = () => {
    const { current } = dropdownRefDesktop;
    if (current) current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(prev => !prev);
    if (!isDropdownVisible) scrollToDropdown();
  };

  const closeDropdown = () => setIsDropdownVisible(false);

  const handleLockScroll = useCallback(() => {
    if (window.innerWidth > 650) {
      unlockScroll();
      return;
    }
    if (isDropdownVisible) lockScroll();
    else unlockScroll();
  }, [isDropdownVisible]);

  useEffect(() => {
    if (!window) return;
    const handleClickOutside = (event: any) => {
      const ref = window.innerWidth < 650 ? dropdownRefMobile : dropdownRefDesktop;
      if (!containerRef.current?.contains(event.target) && !ref?.current?.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleLockScroll);
    return () => {
      unlockScroll();
      window.removeEventListener('resize', handleLockScroll);
    };
  }, []);

  useEffect(() => handleLockScroll(), [isDropdownVisible]);

  return (
    <Dropdown ref={containerRef}>
      <DropdownTrigger>
        <SelectButton onClick={toggleDropdown} data-open={isDropdownVisible ? 'true' : 'false'}>
          <span>{t['select_city']}</span>
          <img src={ChevronDown} alt={isDropdownVisible ? t['close'] : t['open']} width={24} />
        </SelectButton>
        <SearchButton onClick={toggleDropdown}>
          <span>{t['search']}</span>
          <img src={Search} alt={t['search']} width={24} />
        </SearchButton>
      </DropdownTrigger>
      <DropdownContent ref={dropdownRefDesktop} data-open={shouldRenderDesktop ? 'true' : 'false'}>
        <Content type={type} closeDropdown={closeDropdown} />
      </DropdownContent>
      {shouldRenderMobile &&
        createPortal(
          <Overlay>
            <DropdownContentMobile ref={dropdownRefMobile} onClick={e => e.stopPropagation()} $open={isDropdownVisible}>
              <Content type={type} mobile closeDropdown={closeDropdown} />
            </DropdownContentMobile>
          </Overlay>,
          document.body,
        )}
    </Dropdown>
  );
};

interface CitySelectionDropdownProps {
  type: 'long' | 'short';
}
