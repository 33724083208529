/** @format */

import styled from 'styled-components';

export const SelectCityWrapper = styled.div`
  z-index: 1;
  position: relative;
  margin-top: -1px;
  padding-inline: 1rem;
  padding-bottom: 5rem;
  background: ${({ theme }) => theme.color.teal.dark};
`;

export const TabsContainer = styled.div`
  max-width: 38.9rem;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

export const CampusLink = styled.a`
  display: block;
  width: fit-content;
  display: flex;
  gap: 0.5rem;

  text-decoration: underline;
  color: ${({ theme }) => theme.color.white};
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem; /* 150% */
  text-decoration-line: underline;

  @media screen and (max-width: 480px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  @media screen and (max-width: 375px) {
    img {
      display: none;
    }
  }
`;

export const TabButton = styled.button<{ $active: boolean }>`
  color: ${({ theme, $active }) => ($active ? theme.color.yellow : '#f2f4f7')};
  background: none;
  outline: none;
  border: none;
  margin-inline: 0.5rem;
  padding-block: 0.5rem;
  cursor: pointer;

  &:first-of-type {
    margin-left: 0;
  }

  text-align: center;

  border-bottom: 0.125rem solid ${({ $active, theme }) => ($active ? theme.color.yellow : 'transparent')};

  font-family: ${({ theme }) => theme.font.body};
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;
