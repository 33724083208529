/** @format */

import Head from 'next/head';
import { FunctionComponent as FC } from 'react';
export const CorporationLd: FC = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Corporation',
            '@id': 'Corporation',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Viale Monte Nero 6',
              addressLocality: 'Milano',
              addressRegion: 'Lombardia',
              postalCode: '20135',
              addressCountry: 'ITA',
            },
            name: 'Joivy',
            url: 'https://www.joivy.com/en/',
            logo: {
              '@type': 'ImageObject',
              url: 'https://www.joivy.com/logo-512x512.png',
              width: 512,
              height: 512,
            },
            telephone: '+39 02 36697390',
            email: 'inquiries@joivy.com',
            sameAs: '[https://www.joivy.com/it/]',
          }),
        }}
      />
    </Head>
  );
};
