/** @format */

import { Metadata } from '@/types/shared';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, useMemo } from 'react';
import { useLanguageSwitcherContext } from '../../../context/LanguageSwitcherContext';

const prod = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

const MetaData: FC<Props> = ({ meta, noindex = false }) => {
  const { title, description, image } = meta;
  const { slugs } = useLanguageSwitcherContext();
  const router = useRouter();
  const origin = useMemo(
    () => (typeof window !== 'undefined' && window.location.origin ? window.location.origin : ''),
    [],
  );

  // This is the current url of the page: asPath includes also the query parameters
  const canonical = useMemo(() => `${origin}/${router.locale}${router.asPath}`, [origin, router]);
  const alternates = useMemo(
    () =>
      router.locales?.map(locale => {
        if (locale === 'default') {
          const alternate = `${origin}/`;

          return {
            alternate,
            locale: 'x-default',
          };
        }
        const alternate = `${origin}/${locale}`;
        return {
          alternate,
          locale,
        };
      }),
    [router, origin, slugs],
  );

  return (
    <Head>
      <title>{'Joivy | ' + title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:url" content={canonical} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={'Joivy | ' + title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image?.fields.file.url} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="joivy.com" />
      <meta property="twitter:url" content={canonical} />
      <meta name="twitter:title" content={'Joivy | ' + title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image?.fields.file.url} />

      <link rel="canonical" href={canonical} data-baseprotocol="https:" data-basehost={origin} />

      {!prod && <meta name="robots" content="noindex, nofollow"></meta>}
      {prod &&
        (noindex ? (
          <meta name="robots" content="noindex, nofollow"></meta>
        ) : (
          <>
            <meta name="robots" content="index, follow" />
            {alternates?.map(({ alternate, locale }, index) => (
              <link key={index} rel="alternate" hrefLang={locale} href={alternate} />
            ))}
          </>
        ))}

      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="icon" sizes="192x192" href="/android-chrome-192x192.png" />
      <link rel="icon" sizes="512x512" href="/logo-512x512.png" />
      <meta name="theme-color" content="#015962" />
    </Head>
  );
};

export default MetaData;

type Props = {
  meta: Metadata;
  noindex?: boolean;
};
