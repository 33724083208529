/** @format */

import CampusIcon from '@/assets/icons/landing/campus.svg';
import { CitySelectionDropdown } from '@/components/Landing/CitySelectionDropdown';
import { LandingHero } from '@/components/Landing/Hero';
import { CampusLink, SelectCityWrapper, TabButton, TabsContainer } from '@/components/Landing/index.style';
import { PageWrapper } from '@/components/Landing/PageWrapper';
import { CorporationLd } from '@/components/Layout/CorporationLd';
import { useLanguageSwitcherContext } from '@/context/LanguageSwitcherContext';
import { getCompany, getLandingFooter, getLandingNavigation, getPage, getTranslations } from '@/lib/contenful.api';
import { GTMLandingHousingType } from '@/lib/google-tag-manager';
import { Company, LandingPageContent, Menu, Translations } from '@/types/page';
import { Entry } from 'contentful';
import { GetStaticProps, NextPage } from 'next';
import { useEffect, useState } from 'react';

const Index: NextPage<Props> = ({ navigation, content, footer, company, translations }) => {
  const { setSlugs } = useLanguageSwitcherContext();
  const [selectionType, setSelectionType] = useState<'long' | 'short'>('long');
  const { landing: t } = translations.fields.translations;

  const toggleSelectionType = (newSelectionType: string) => {
    if (newSelectionType !== 'long' && newSelectionType !== 'short') return;
    if (newSelectionType === selectionType) return;

    const gtmType = newSelectionType === 'long' ? 'coliving' : 'travel_and_corporate';
    GTMLandingHousingType(gtmType);

    setSelectionType(newSelectionType);
  };

  useEffect(() => {
    const HomeSlugs = {
      en: '',
      it: '',
      es: '',
      fr: '',
      pt: '',
    };
    setSlugs(HomeSlugs);
  }, []);

  return (
    <PageWrapper
      meta={content.seo.fields}
      pageType="home"
      navigation={navigation}
      footer={footer}
      company={company}
      translations={translations}
      slug={content.slug.fields.slug}
    >
      <CorporationLd />
      <LandingHero title={content.body} />
      <SelectCityWrapper>
        <TabsContainer>
          {['long', 'short'].map(type => (
            <TabButton key={type} onClick={() => toggleSelectionType(type)} $active={selectionType === type}>
              {t[`${type}_stay`]}
            </TabButton>
          ))}
        </TabsContainer>
        <CitySelectionDropdown type={selectionType} />
        <CampusLink
          href="https://www.campus.dovevivo.com/en/"
          target="_blank"
          onClick={() => GTMLandingHousingType('campus')}
        >
          <img src={CampusIcon} alt="campus" role="presentation" width={24} />
          <span>{t['looking_for_uni']}</span>
        </CampusLink>
      </SelectCityWrapper>
    </PageWrapper>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const localeToFetch = () => {
    if (!locale) return 'en';
    if (locale === 'default') return 'en';
    return locale;
  };

  const navigation = await getLandingNavigation(localeToFetch());
  // TODO: Remove cards from contentful
  const content = await getPage('landingPage', localeToFetch(), '/');

  const footer = await getLandingFooter(localeToFetch());
  const company = await getCompany(localeToFetch());
  const translations = await getTranslations(localeToFetch());

  return {
    notFound: locale === 'default',
    props: {
      navigation,
      content,
      footer,
      company,
      translations,
    },
  };
};

type Props = {
  content: LandingPageContent;
  navigation: Entry<Menu>;
  footer: Entry<Menu>;
  company: Entry<Company>;
  translations: Entry<Translations>;
};

export default Index;
