/** @format */

import styled from 'styled-components';

export const Dropdown = styled.div`
  position: relative;
  margin: 0 auto;
  line-height: 1.5;
  max-width: 38.9rem;
`;

export const DropdownTrigger = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: clamp(3rem, -0.0444rem + 13.5307vw, 7rem);

  @media screen and (min-width: 833px) {
    margin-bottom: clamp(6.753rem, 7.6319rem + -1.2123vw, 7rem);
  }

  button {
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    font-family: ${({ theme }) => theme.font.body};
  }
`;

export const SelectButton = styled.button`
  flex-grow: 1;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.color.white};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  align-items: flex-start;
  justify-content: space-between;
  --rotation: 0deg;
  column-gap: 0;
  color: rgb(102, 112, 133);

  img {
    transition: transform 0.3s ease-in-out;
    transform: rotate(var(--rotation));
  }

  &[data-open='true'] {
    --rotation: 180deg;
  }
`;

export const SearchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.125rem;
  background: ${({ theme }) => theme.color.yellow};

  span {
    color: ${({ theme }) => theme.color.teal.dark};

    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;

    @media screen and (max-width: 833px) {
      display: none;
    }
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  width: 45rem;

  @media screen and (max-width: 833px) {
    width: 100%;
  }

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 0.5rem;
  padding: 1.5rem;
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.color.white};
  color: #475467;
  box-shadow: 0 24px 48px -12px rgba(16, 24, 40, 0.18);

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75ren;
  text-align: start;

  scroll-margin: 1rem;

  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.36, 0.33, 0, 1);

  &[data-open='true'] {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  ul {
    list-style: none;
  }

  @media screen and (max-width: 650px) {
    display: none !important;
  }
`;

export const Overlay = styled.div`
  @media screen and (min-width: 651px) {
    display: none;
  }

  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 5;
`;

export const DropdownContentMobile = styled.div<{ $open?: boolean }>`
  ul {
    padding: 0;
    list-style: none;
  }

  @media screen and (min-width: 651px) {
    display: none;
  }

  --content-padding-inline: 1rem;
  --content-padding-block: 1.81rem;
  --border-radius: 1rem;
  --animation: ${({ $open }) => ($open ? 'fade-in' : 'fade-out')};

  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 4;
  overflow-y: scroll;
  overflow-x: hidden;
  animation: var(--animation) 0.4s cubic-bezier(0.36, 0.33, 0, 1);
  height: 71.5vh;
  height: 71.5dvh;
  isolation: isolate;

  border-radius: var(--border-radius) var(--border-radius) 0 0;

  padding-inline: var(--content-padding-inline);

  @keyframes fade-in {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }

  @keyframes fade-out {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(100%);
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
