/** @format */
import ChevronRightActive from '@/assets/icons/landing/chevron_right_dark.svg';
import ChevronRight from '@/assets/icons/landing/chevron_right_light.svg';
import CloseIcon from '@/assets/icons/landing/close.svg';
import { i18nContext } from '@/context/i18nContext';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CitiesToRender } from './CitiesToRender';
import { CITIES_LONG, CITIES_SHORT } from './constants/cities';
import { CitiesList, CountriesList, CountryItem, MobileHeader, MobileSticky } from './Content.style';

export const Content: FC<Readonly<ContentProps>> = ({ mobile = false, closeDropdown, type }) => {
  const { landing: t } = useContext(i18nContext);

  const cities = useMemo(() => (type === 'long' ? CITIES_LONG : CITIES_SHORT), [type]);

  const citiesPerCountry = useCallback(
    (country: string) => cities.filter(city => city.country === country && city.name !== country).length,
    [cities],
  );
  const countries = useMemo(() => {
    return Array.from(new Set(cities.map(x => x.country))).sort((a, b) => {
      if (citiesPerCountry(a) > citiesPerCountry(b)) return -1;
      if (citiesPerCountry(a) < citiesPerCountry(b)) return 1;
      return 0;
    });
  }, [cities]);

  const [activeCountry, setActiveCountry] = useState(countries[0]);

  const gridRows = useMemo(() => {
    if (mobile) return;
    const lenghts = countries.map(country => citiesPerCountry(country));
    return Math.ceil(Math.max(...lenghts) / 2);
  }, [cities]);

  useEffect(() => {
    setActiveCountry(countries[0]);
  }, [countries]);

  return (
    <>
      <MobileSticky>
        <MobileHeader>
          <p>{t['select_city']}</p>
          <button className="close" onClick={closeDropdown}>
            <img src={CloseIcon} alt={t['close']} width={14.4} />
          </button>
        </MobileHeader>
        <CountriesList>
          {countries.map(country => (
            <CountryItem onClick={() => setActiveCountry(country)} key={country} $active={country === activeCountry}>
              <button>
                <span>
                  {t[country]} <span className="number">({citiesPerCountry(country)})</span>
                </span>
                <img src={country === activeCountry ? ChevronRightActive : ChevronRight} alt={t['open']} width={24} />
              </button>
            </CountryItem>
          ))}
        </CountriesList>
      </MobileSticky>
      <CitiesList $rows={gridRows}>
        <CitiesToRender cities={cities} activeCountry={activeCountry} closeDropdown={closeDropdown} />
      </CitiesList>
    </>
  );
};

interface ContentProps {
  mobile?: boolean;
  closeDropdown?: () => void;
  type: 'long' | 'short';
}
