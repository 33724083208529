/** @format */

import { useCallback, useEffect } from 'react';
import { useWindowWidth } from './useWindowWidth';

export const useLockScroll = () => {
  const windowWidth = useWindowWidth();
  const lockScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = 'var(--scrollbar-compensation)';
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '0';
  }, []);

  useEffect(() => {
    if (!windowWidth) return;
    const scrollBarCompensation = windowWidth - document.documentElement.clientWidth;
    document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`);
  }, [windowWidth]);

  return {
    lockScroll,
    unlockScroll,
  };
};
