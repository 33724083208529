/** @format */

import styled from 'styled-components';

export const MobileSticky = styled.div`
  @media screen and (max-width: 650px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: ${({ theme }) => theme.color.white};
  }

  padding-top: var(--content-padding-block);
`;

export const MobileHeader = styled.div`
  @media screen and (min-width: 651px) {
    display: none;
  }

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #101828;
  text-align: center;

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;

  p {
    margin: 0;
  }

  button {
    position: absolute;
    right: 0;
    border: none;
    border-radius: 50%;
    width: 24px;
    aspect-ratio: 1;
    display: grid;
    place-content: center;
  }
`;

export const CountriesList = styled.ul`
  display: grid;
  gap: 0.25rem;
  align-self: start;

  @media screen and (max-width: 650px) {
    display: flex;
    align-items: center;
    gap: 1rem;
    border-bottom: 0.0625rem solid #d9d9d9;

    overflow-x: scroll;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    img {
      display: none;
    }
  }
`;

export const CountryItem = styled.li<{ $active: boolean }>`
  display: grid;
  align-items: stretch;

  * {
    font-family: ${({ theme }) => theme.font.body};
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.5rem 1.31rem;
    padding-left: 1rem;

    border-left: 5px solid transparent;

    color: ${({ $active, theme }) => ($active ? theme.color.teal.dark : '#475467')};
    font-size: 1.125rem;
    font-weight: ${({ $active }) => ($active ? 700 : 500)};
    line-height: 1.75rem;

    .number {
      color: ${({ $active, theme }) => ($active ? theme.color.teal.dark : '#667085')};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;

      @media screen and (max-width: 650px) {
        color: var(--gray-600, #475467);
      }
    }

    transition: background 0.3s ease-in-out;

    &:hover {
      background: #fcfcfd;
    }

    ${({ $active, theme }) => {
      const activeStyles = `
          border-left: 5px solid ${theme.color.teal.dark};
          background: #FCFCFD;
          `;

      if ($active) return activeStyles;
    }}

    @media screen and (max-width: 650px) {
      color: #344054;
      text-align: center;
      font-size: 1rem;
      font-weight: ${({ $active }) => ($active ? 600 : 400)};
      line-height: 1.5;
      border: none;

      background: none;

      width: fit-content;
      padding: 0.5rem;
      padding-bottom: 0;

      &:hover {
        background: none;
      }

      span:not(.number) {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        padding-bottom: 0.5rem;
        border-bottom: 0.125rem solid ${({ $active, theme }) => ($active ? theme.color.teal.dark : 'transparent')};
      }

      &:first-of-type {
        padding-left: 0;
      }
    }
  }
`;

export const CitiesList = styled.ul<{ $rows?: number }>`
  display: grid;
  grid-column: 2 / -1;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${({ $rows }) => `repeat(${$rows}, 1fr)`};
  column-gap: 1.5rem;

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0.25rem;
    margin-block: 1em;
  }
`;

export const CityItem = styled.li`
  a {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5ch;
    color: #101828;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.5rem;
    font-weight: 400;

    transition: all 0.3s ease-in-out;

    &:hover {
      background: #f9fafb;
    }

    @media screen and (max-width: 650px) {
      padding: 0.5rem 0;
      color: #101828;
    }

    .hot {
      color: ${({ theme }) => theme.color.pink};
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.25rem;
    }
  }
`;
